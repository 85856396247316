import { useEffect, useState } from "react";
import logo from "../assets/polar.png";
import phone from "../assets/phone.png";

export default function SplashView(props: any) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        style={{
          width: "100vw",
          minHeight: "100vh",
          backgroundColor: "#57B231",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: "32px",
            marginLeft: "32px",
            marginBottom: "32px",
            marginRight: "32px",
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #346B1D",
            borderRadius: "56px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <img
            src={logo}
            style={{
              width: isMobile ? "120px" : "160px",
              height: isMobile ? "28.5px" : "38px",
              userSelect: "none",
              zIndex: 1,
              position: "relative",
              marginTop: "40px",
            }}
          />
          <div
            style={{
              fontFamily: "inter600",
              fontSize: isMobile ? "20px" : "32px",
              lineHeight: isMobile ? "24px" : "40px",
              color: "#ffffff",
              textAlign: "center",
              marginTop: "32px",
              marginBottom: "56px",
              marginRight: "40px",
              marginLeft: "40px",
            }}
          >
            Köszönjük, hogy részt vettél a nyereményjátékban!
          </div>
          <img
            src={phone}
            style={{
              marginTop: "auto",
              width: "80%",
              maxWidth: "365px",
              height: "auto",
              userSelect: "none",
              zIndex: 1,
              position: "relative",
            }}
          />
        </div>
      </div>
    </>
  );
}
