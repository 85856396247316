import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SplashView from "./views/splashView";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SplashView />} />
        {/*<Route path="/g" element={<GameView />} />
        <Route path="/reset-password" element={<ResetPasswordRedirect />} />*/}
      </Routes>
    </BrowserRouter>
  );
}
